import dayjs from "dayjs";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";

export const post = async (
    url: string,
    body: {} = {},
    method: string = "GET",
    files: any | null = null
): Promise<any> => {
    const csrfElement = document.querySelector("[name=csrfmiddlewaretoken]");
    const csrftoken = csrfElement ? (csrfElement as HTMLInputElement).value : "";

    const headers: Record<string, string> = {
        "X-CSRFToken": csrftoken,
    };

    const args: RequestInit = {
        method,
        credentials: "same-origin",
        headers,
        body: null,
    };

    if (method === "GET") {
        const get_params = new URLSearchParams(body).toString();
        url = url + "?" + get_params;
    }

    if (method !== "GET") {
        if (files) {
            const form_data = new FormData();
            for (let i = 0; i < files.length; i++) {
                form_data.append("file" + i, files[i]);
            }
            for (let key in body) {
                // @ts-ignore
                form_data.set(key, body[key]);
            }
            args.body = form_data;
        } else {
            args.body = JSON.stringify(body);
            headers["Content-Type"] = "application/json; charset=UTF-8";
        }
    }

    try {
        const response = await fetch(url, args);
        if (response.ok) {
            const contentType = response.headers.get("Content-Type");
            if (contentType === "text/html; charset=utf-8") {
                return response.text();
            } else if (contentType === "application/json") {
                return response.json();
            } else {
                return response;
            }
        } else {
            let error = await response.text();
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export const downloadAsImage = async (elementId: string, filename: string, widthFix: boolean = true): Promise<void> => {
    const element = document.getElementById(elementId);

    if (element) {
        let width = element?.clientWidth + 2;
        let height = element?.clientHeight;

        if (widthFix) {
            width = element?.clientWidth + 80;
            height = element?.clientHeight + 20;
        }

        toPng(element as HTMLElement, { height: height, width: width }).then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = `${filename}.png`;
            link.href = dataUrl;
            link.click();
        });
    } else {
        console.log(`Element with id ${elementId} not found.`);
    }
};

export const localDate = (string: any): string => {
    if (string == null || string == "" || string == undefined) return "-";

    return dayjs(string).format("MMM DD, YYYY");
};

export const localDatetime = (string: any): string => {
    if (string == null || string === "" || string === undefined) return "-";

    return dayjs(string).format("MMM DD, YYYY hh:mm A");
};

export const localString = (value: number | null, round: number = 2): string => {
    if (value == null) return "-";
    return value.toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: round,
        minimumFractionDigits: round,
    });
};

export const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z]+[a-zA-Z0-9.-_]*@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]{2,}$/;
    return re.test(email);
};

export const ramGiB = (bytes: number): number => {
    let num = 0;
    num = Math.round(bytes / 1024);

    if (num % 8 !== 0) {
        num = Math.ceil(num / 8) * 8; // Otherwise, find the next multiple of 8
    }

    return num;
};

export const hasGroup = (groups: string[], group: string): boolean => {
    if (groups == undefined) return false;

    return groups.indexOf(group) !== -1;
};

export const downloadAttachment = async (
    project_id: number | undefined,
    attachment_id: number,
    attachment_name: string
) => {
    if (!project_id) return;

    try {
        const aws_url = await post("attachments/", { project_id, id: attachment_id }, "PUT");
        const anchor = document.createElement("a");
        anchor.href = aws_url;
        anchor.target = "_blank";
        anchor.download = attachment_name;
        anchor.click();
    } catch (error) {
        console.error(error);
    }
};

export const isNull = (value: any): boolean => {
    return value === null || value === undefined || value === "" || value === 0;
};

export const downloadAsPng = (elementId: string, filename: string) => {
    const element = document.getElementById(elementId);

    html2canvas(element as HTMLElement).then((canvas) => {
        const link = document.createElement("a");
        link.download = `${filename}.png`;
        link.href = canvas.toDataURL("image/png");
        link.click();
    });
};
